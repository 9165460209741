import { cssBundleHref } from "@remix-run/css-bundle";
import {
  json,
  type DataFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction } from
"@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
"@remix-run/react";
// import { Suspense } from 'react'
import { withSentry } from "@sentry/remix";
import { Confetti } from "./components/confetti.tsx";
import { GeneralErrorBoundary } from "./components/error-boundary.tsx";
import { href as iconsHref } from "./components/ui/icon.tsx";
import { Toaster } from "./components/ui/toaster.tsx";
import { useTheme } from "./routes/resources+/theme/index.tsx";
import { getTheme } from "./routes/resources+/theme/theme.server.ts";
import fontStylestylesheetUrl from "./styles/font.css";
import tailwindStylesheetUrl from "./styles/tailwind.css";
import rdtStylesheet from "remix-development-tools/index.css";
import { authenticator, getUserId } from "./utils/auth.server.ts";
import { ClientHintCheck, getHints } from "./utils/client-hints.tsx";
import { prisma } from "./utils/db.server.ts";
import { getEnv } from "./utils/env.server.ts";
import { getFlashSession } from "./utils/flash-session.server.ts";
import { combineHeaders, getDomainUrl } from "./utils/misc.ts";
import { useNonce } from "./utils/nonce-provider.ts";
import { makeTimings, time } from "./utils/timing.server.ts";
import { useToast } from "./utils/useToast.tsx";
import { checkDomain } from "./utils/misc.server.ts";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import { csrf } from "./utils/csrf.server.ts";

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: "preload", href: iconsHref, as: "image" },
  // Preload CSS as a resource to avoid render blocking
  { rel: "preload", href: fontStylestylesheetUrl, as: "style" },
  { rel: "preload", href: tailwindStylesheetUrl, as: "style" },
  cssBundleHref ? { rel: "preload", href: cssBundleHref, as: "style" } : null,
  ...(process.env.NODE_ENV === "development" ?
  [{ rel: "stylesheet", href: rdtStylesheet }] :
  []), (
  // { rel: "mask-icon", href: "/favicons/mask-icon.svg" },
  // {
  //   rel: "alternate icon",
  //   type: "image/png",
  //   href: "/favicons/favicon-32x32.png",
  // },
  // { rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
  {
    rel: "manifest",
    href: "/site.webmanifest",
    crossOrigin: "use-credentials"
  } as const), // necessary to make typescript happy
  { rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.ico" },
  { rel: "stylesheet", href: fontStylestylesheetUrl },
  { rel: "stylesheet", href: tailwindStylesheetUrl },
  cssBundleHref ? { rel: "stylesheet", href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? "JPGApps" : "Error | JPGApps" },
  { name: "description", content: `JPG Applications` }];

};

export async function loader({ request }: DataFunctionArgs) {
  checkDomain(request);
  const timings = makeTimings("root loader");
  const userId = await time(() => getUserId(request), {
    timings,
    type: "getUserId",
    desc: "getUserId in root"
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUnique({
      where: { id: userId },
      select: {
        id: true,
        firstName: true,
        username: true,
        imageId: true
      }
    }),
    { timings, type: "find user", desc: "find user in root" }
  ) :
  null;
  if (userId && !user) {
    console.info("something weird happened");
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await authenticator.logout(request, { redirectTo: "/" });
  }
  const { flash, headers: flashHeaders } = await getFlashSession(request);

  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      flash,
      csrfToken
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        flashHeaders,
        csrfCookieHeader ? { "set-cookie": csrfCookieHeader } : null
        // {
        //   "Content-Security-Policy":
        //     "frame-ancestors https://collarnarycookoff.com/;",
        //   "X-Frame-Options": "ALLOW-FROM https://collarnarycookoff.com/;",
        // }
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    "Server-Timing": loaderHeaders.get("Server-Timing") ?? ""
  };
  return headers;
};

function Document({
  children,
  nonce,
  theme = "light",
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: "dark" | "light";env?: Record<string, string>;}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="!mr-0 bg-background text-foreground">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
      </body>
    </html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();
  useToast(data.flash?.toast);

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
      <div className="">
        <Outlet />
      </div>
      <Confetti confetti={data.flash?.confetti} />
      <Toaster />
    </Document>);

}

let AppExport = App;
if (process.env.NODE_ENV === "development") {
  const { withDevTools } = await import("remix-development-tools");
  AppExport = withDevTools(AppExport);
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
      <AppExport />
    </AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>);

}